.faqContainer {
    padding: 1vh 0.2vw !important;
    margin: 14vh auto !important;
    width: 70% !important;
}

.expandIcon {
    color: #d18726 !important;
    font-size: 1.8rem !important;
}

.questionIcon {
    color: #d18726 !important;
    font-size: 1.4rem !important;
    margin-right: 0.5vw !important;
}

.faqAccordionHeader {
    background-color: #d5d5d5 !important;
}

.faqAccordionBody {
    background-color: #fbfaf8 !important;
}

.faqAccordionContainer {
    margin: 4vh auto !important;
}

@media (min-width: 2000px) {
    .heading {
        font-size: 5rem !important;
    }

    .questionIcon {
        font-size: 82rem !important;
    }

    .faqAccordionHeader h6 {
        font-size: 3rem !important;
    }

    .accordion-body {
        font-size: 2.5rem !important;
    }

}