.accountInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d6d1cb !important;
    box-sizing: border-box;
    margin: 4vh auto !important;
    width: 80% !important;
    text-align: center;
    padding: 20px;
    border-radius: 0.5vw;
}

.accountButton {
    background-color: #908585 !important;
    border-color: #908585 !important;
    color: #1a1a1a !important;
    margin-bottom: 2vh !important;
}

.accountButton:hover {
    background-color:  #3e3c3c !important;
    color: white !important;
}

.accountInfoForm  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2vh;
}

.accountInfoTitle {
    font-size: 1.5rem !important;
    color: #1a1a1a !important;
    font-weight: 600 !important;
}

.accountInfoForm .MuiBox-root {
    width: 100%;
    max-width: 400px;
    background-color: transparent !important;
}

.accountInput {
  margin: 1vh !important;
}

  .accountInput .MuiFilledInput-root:hover {
    border-color: blue;
  }
  
  .accountInput .MuiFilledInput-root.Mui-focused {
    border-color: green !important;
  }

  .accountInput .MuiFilledInput-underline:after {
    border-color: #d18726 !important;
  }
  
  .accountInput .MuiInputLabel-root.Mui-focused {
    color: #d18726 !important;
  }

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
}

@media(min-width: 2000px) {
  .accountButton {
    width: 40% !important;
  }
}
