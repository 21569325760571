.contactPageContainer {
    padding: 4vw;
    background-color: #d6d1cb;
    border-radius: 1vw;
}

.mapsd {
    margin: 6vh auto !important;
    text-align: center !important;
    display: flex !important;
    justify-items: center !important;
    align-items: center !important;
    justify-content: center;
    align-content: center !important;
    justify-self: center !important;
    align-self: center !important;
}

.contactInfo h6, .addressInfo h6 {
    font-size: 2rem !important;
}

.contactInfo span, .addressInfo address {
    display: block !important;
    font-size: 1.3rem;
    margin-top: 0.5vh !important;
}

.contactInfo strong {
    color: #d18726;
    font-size: 1.5rem;
}

.addressInfo strong {
    color: #d18726;
    font-size: 1.5rem;
}

@media (max-width: 900px) {
    .css-zow5z4-MuiGrid-root {
        width: 100% !important;
        margin-left: 0 !important;
    }

}