.pageNotFound {
    display: flex;
    flex-direction: column !important;
}

.contentInfo {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 4vh auto !important;
}

.amberSign {
    color: orange !important;
    font-size: 6rem !important;
}

.errorHeading {
    font-size: 10rem !important;
}

.notf {
    background: -webkit-linear-gradient(#d18726, #806044);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.notFoundText {
    font-size: 2rem !important;
}


@media (min-width: 2000px) {
    .amberSign {
        font-size: 10rem !important;
    }

    .errorHeading {
        font-size: 14rem !important;
    }

    .notFoundText {
        font-size: 6rem !important;
    } 
}
  