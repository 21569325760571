.homeContainer {
    background-color: #fbfaf8;
    margin: 0 !important;
}

.homeImage {
    position: relative;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.text-overlay {
    position: absolute;
    top: 40%; 
    left: 20%; 
    transform: translate(-50%, -50%);
    color: #cbb89d; 
    padding: 10px;
    text-align: left;
    font-family: "Cormorant SC" !important;
    font-size: 4rem !important;
}

.homeImage span {
    font-family: 'Vivaldi';
    font-size: 5rem !important;
}

.homeImage img {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.categoryName {
  font-family: "Cormorant SC"; 
  font-size: 1.5rem !important; 
  margin: 3vh auto !important;
}

.categoryContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 4vh;
}

.shopButton {
    background-color: #412f26  !important;
    color: #806044 !important;
    margin: 2vh auto !important;
    width: 10% !important;
    height: 7% !important;
    border-radius: 0.5vw !important;
    font-size: 1.2rem !important;
    text-transform: capitalize;
    text-decoration: none !important;
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
}

.shopButton:hover {
    background-color: #806044 !important;
    color: white !important;
}

.card {
    margin: 2vh auto !important;
    border-radius: 0.5vw !important;
    width: 85% !important;
    height: 85% !important;
}

.cardTitle {
    font-family: "Corbel Bold" !important;
    font-size: 2rem !important;
    color: #1a1a1a !important;
}

.cardBody {
    font-family: "Corbel Regular" !important;
    font-size: 1.5rem !important;
    color: #1a1a1a !important;
}

.card2 {
    margin: 2vh auto !important;
    border-radius: 0.5vw !important;
    background-image: linear-gradient(30deg, #8d8282, #2d2a2a) !important;
    padding: 2vh !important;
    width: 85% !important;
    height: 100% !important;
}

.cardBody2 {
    text-align: left;
    font-family: "Corbel Regular" !important;
    font-size: 1.5rem !important;
    color: #1a1a1a !important;
    width: 60%
}

.cardTitle2 {
    font-family: "Corbel Bold" !important;
    font-size: 2rem !important;
    color: #1a1a1a !important;
    width: 50%;
    text-align: left;
}

.teamSectionTitle {
    font-size: 2rem !important;
    font-weight: 500 !important;
    color: #A86D1E;
    margin: 2vh auto !important;
}

.teamSection {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    margin: 2vh auto !important;
}

.teamMember {
    margin: 2vh auto !important;
}

.memberInfo {
    text-align: left;
    margin-left: 12vw !important;
    margin-top: 2vh !important;
    font-family: "Corbel Regular" !important;
    font-size: 1.5rem !important;
}


@media (max-width: 600px) {
    .text-overlay {
        position: absolute;
        top: 35%; 
        left: 25%; 
        transform: translate(-50%, -50%);
        color: #cbb89d; 
        text-align: left;
        font-family: "Cormorant SC" !important;
        font-size: 1.6rem !important;
    }

    .homeImage span {
        font-family: 'Vivaldi';
        font-size: 1.7rem !important;
    }

    .categoryName {
        font-size: 1.2rem !important;
    }

    .categoryContainer {
        margin: auto !important;
        text-align: center !important;
        display: flex;
        align-self: center !important;
    }

    .teamMember img {
        width: 200px !important;
    }

    .memberInfo {
        text-align: center !important;
        margin: 2vh auto !important;
    }
  }

  @media (max-width: 900px) {
    .categoryContainer img {
        width: 300px !important;
        margin: 4vh auto !important;
    }

    .text-overlay {
        left: 30% !important;
    }

    .shopButton {
        width: 120px !important;
        height: 40px !important;
        border-radius: 1vw !important;
    }

    .memberInfo {
        text-align: center !important;
        margin: 2vh auto !important;
    }

    .card {
        margin: 2vh auto !important;
        border-radius: 0.5vw !important;
        width: 85% !important;
        height: 90% !important;
    }

    .memberInfo {
        text-align: center !important;
        margin: 2vh auto !important;
    }

  }

  @media (min-width: 900px) and (max-width: 1200px) {
    .categoryContainer img {
        width: 200px !important;
    }
  }

  @media (min-width: 2000px) {
    .categoryName {
        font-size: 3rem !important;
    }

    .categoryContainer img {
        width: 90% !important;
    }

    .shopButton {
        width: 20% !important;
        height: 9% !important;
        font-size: 2rem !important;
    }

    .card {
        height: auto !important;
    }

    .card img {
        height: 500px !important;
        width: 100% !important;
    }

    .cardTitle, .cardTitle2 {
        font-size: 4rem !important;
    }

    .cardBody, .cardBody2 {
        font-size: 3rem !important;
    }
  }