.text-overlayd {
    position: absolute;
    top: 50%; 
    left: 50%; 
    color: white;
    font-size: 20rem;
    font-family: "Corbel Bold" !important;
    transform: translate(-50%, -50%);
}

.iconContainer {
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 1vh 2vw !important;
    border-radius: 1vw !important;
    margin-top: 4vh !important;
    margin-right: 1vw !important;
}

.iconText {
    margin-top: 3vh !important;
    color: white;
    font-family: "Corbel Bold" !important;
    font-weight: 600 !important;
}

.shopAll {
    display: block !important;
}


@media (max-width: 900px) {
    
    .text-overlayd {
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        text-align: left;
        font-size: 3rem !important;
    }

    .iconContainer {
        margin: 2vh auto !important;
        width: 60% !important;
    }

    .iconContainer img {
        width: 50% !important;
    }

    .shopAll { 
        margin: auto !important;
    }

    .dvd {
        margin: auto !important;
    }

    .css-a0nq30-MuiGrid-root {
        margin: auto !important;
    }
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    .iconContainer {
        margin: 2vh auto !important;
        width: 20% !important;
    }

    .iconText {
        font-size: 2rem !important;
    }

    .iconContainer img {
        width: 30% !important;
        height: 30% !important;
    }

    .shopAll { 
        margin: auto !important;
    }

    .dvd {
        margin: auto !important;
    }

    .css-a0nq30-MuiGrid-root {
        margin: auto !important;
    }

  }

  @media (min-width: 2000px) {
    .categoryName {
        font-size: 3rem !important;
    }

    .categoryContainer img {
        width: 90% !important;
    }

    .shopButton {
        width: 20% !important;
        height: 9% !important;
        font-size: 2rem !important;
    }

    .card {
        height: auto !important;
    }

    .card img {
        height: 500px !important;
        width: 100% !important;
    }

    .cardTitle, .cardTitle2 {
        font-size: 4rem !important;
    }

    .cardBody, .cardBody2 {
        font-size: 3rem !important;
    }
  }