@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.css-1oqqzyl-MuiContainer-root {
  padding: 0 !important;
}

body { 
  background-color: #fdfafa;
  margin: 0 !important;
  padding: 0 !important;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* App.css */
#root {
  text-align: center;
  margin: 1.2rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.heading {
  font-size: 2.5rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.1vw !important;
  margin: 1vh auto !important;
}

@media (max-width: 600px) {
  .heading {
    margin: 2vh 0 !important;
  }
}

@media (min-width: 4000px) {
  body {
    max-width: auto !important;
  }
}