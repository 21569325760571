.backArrow {
    color: #1a1a1a !important;
    font-size: 1.3rem !important;
    align-items: center !important;
    text-transform: capitalize !important;
    text-decoration: none !important;
}

.backArrow:hover {
    color: #d18726 !important;
}

.addToCartt {
    background-color: #d18726 !important;
    color: #1a1a1a !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    border: 1px solid #d49f60 !important;
    border-radius: 0.5vw !important;
    text-align: center !important;
    text-transform: uppercase;
    margin: 1vh auto !important;
    padding: 0.5vh 1.2vw !important;
    box-shadow: none !important;
}

.addToCartt:hover {
    background-color: transparent !important;
    color: #1a1b19 !important;
    font-weight: 500 !important;
}

.productName {
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    color: #655348 !important;
    font-family: "Centaur Regular" !important;
} 

.price {
    font-size: 1.4rem !important;
    font-weight: 500 !important;
    color: #22211f !important;
    font-family: "Corbel" !important;
}

.productDescription {
    font-size: 1.1rem !important;
    text-align: left !important;
}

.accordion-con {
    border: 2px solid #1a1a1a !important;
}

.accordionContainer {
    background-color: #fdfafa !important;
    padding: 1vh 0.2vw !important;
    margin: 14vh 12vw !important;
    width: 25% !important;
    box-shadow: none !important;
}

.expandIcon {
    background-color: #1a1a1a !important;
    color: white !important;
    font-size: 1.8rem !important;
    border-radius: 50% !important;
}

.questionIcon {
    color: #d18726 !important;
    font-size: 1.4rem !important;
    margin-right: 0.5vw !important;
}

.accordionHeader {
    background-color: #fdfafa !important;
}

.accordionBody {
    background-color: #d9d8d6 !important;
}

.accordionContainer {
    margin: 4vh auto !important;
}

.ratingContainer {
    background-color: #d6d1cb !important;
    display: flex !important;
    flex-direction: column !important;
    width: 40% !important;
    margin: auto !important;
    border-radius: 0.5vw !important;
    padding: 2vw !important;
}

.buttonContainer {
    text-align: right !important;
    margin-top: 2vh !important;
}

.reviewButton {
    background-color: #1a1a1a !important;

}

.reviewButton:hover {
    background-color: #d18726 !important;
}

.similarProductsContainer {
    display: flex !important;
    flex-direction: column !important;
}

.similarTextContainer { 
    margin: 4vh auto 1vh 30% !important;

}

.similarProductsText {
    font-size: 1.5rem !important;
    text-align: start !important;
}

.showcasedProducts {
    display: flex !important;
    flex-direction: row !important;
    margin: auto auto 4vh auto !important;
}


@media (max-width: 600px) {
    .cardImg {
        margin-top: 8vh !important;
    }

    .accordionContainer {
        width: 80% !important;
    }
    
    .ratingContainer {
        width: 80% !important;
    }
    
    .similarProductsText {
        text-align: center !important;
    }

    .similarTextContainer {
        margin: 4vh auto !important;
        text-align: center !important;
    }

  }

  @media (max-width: 900px) {
    .accordionContainer {
        width: 70% !important;
    }
    
    .ratingContainer {
        width: 70% !important;
    }

    .showcasedProducts {
        margin: 4vh auto !important;
    }

    .similarTextContainer {
        margin: 4vh auto !important;
        text-align: center !important;
    }
  }

  @media (min-width: 910px) and (max-width: 1200px) {
    .cardImg {
        margin-top: 8vh !important;
    }

    .accordionContainer {
        width: 60% !important;
    }

    .ratingContainer {
        width: 60% !important;
    }

    .similarProductsText {
        text-align: center !important;
    }

    .similarTextContainer {
        margin: 4vh auto !important;
        text-align: center !important;
    }
    
  }