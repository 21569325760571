.cartContainer {
    background-color: #d6d1cb;
    border-radius: 2vh !important;
    padding: 1vh 6vw !important;
    margin: 3vw !important;
}

.productContainer {
    background-color: #fcfbf9;
    display: flex;
    justify-content: space-between; 
    margin-bottom: 2vh;
}

.removeButton {
    background-color: transparent !important;
    color: red !important;
    border: 1px solid red !important;
    margin: 1vh 0.3vw !important;
    border-radius: 0.5vw !important;
    box-shadow: none !important;
}

.removeButton:hover {
    background-color: rgb(240, 129, 129) !important;
    border: 1px solid rgb(240, 129, 129) !important;
    color: white !important;
    font-weight: 600 !important;
}

.clearButton {
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid red !important;
    border-radius: 0.5vw !important;
    align-self: flex-end !important; 
    margin-top: 4vh !important;
}

.clearButton:hover {
    background-color: rgb(240, 129, 129) !important;
    border: 1px solid rgb(240, 129, 129) !important;
    font-weight: 600 !important;
}

.orderSummary {
    display: flex !important; 
    justify-content: space-between !important; 
    flex-flow: row !important;
    margin-top: 4vh !important;
}

.subTotal {
    font-size: 1.3rem !important;
    align-items: flex-start !important;
}

.subTotalPrice {
    font-size: 1.3rem !important;
    align-items: flex-end !important;
}

.deliveryInfo {
    display: flex !important;
    flex-flow: row !important;
    justify-content: space-between !important; 
    margin-top: 2vh !important;
}

.deliveryFee {
    display: flex !important; 
    align-content: baseline !important;
    font-size: 1.3rem !important;
    margin-top: 1vh !important;
}

.totalContainer {
    display: flex !important;
    flex-flow: row !important;
    justify-content: space-between !important; 
    margin-top: 2vh !important;
}

.total { 
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    align-items: flex-start !important;
}

.totalPrice {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    align-items: flex-end !important;
}

.checkout {
    background-color: #1a1a1a !important;
    text-transform: capitalize;
    color: white;
    border-radius: 0.5vw !important;
    margin: 2vh auto !important;
}

.checkout:hover {
    background-color: #d18726 !important;
    color: #ffffff !important;
    font-weight: 600 !important;
}

.checkout:hover {
    background-color: rgb(19, 214, 19);
}

.deliveryStatus {
    display: flex;
    justify-content: baseline;
    margin-top: 2vh;
}

.deliveryIcon {
    margin-right: 0.5vw !important;
    align-self: center !important
}

.deleteIcon {
    color: rgb(255, 78, 78);
}

@media (max-width: 900px) {
    .deliveryInfo {
        display: flex !important;
        flex-direction: column !important;
    }

    .deliveryIcon {
        margin-right: 2vw !important;
    }
}

@media (min-width: 2000px) {
    .removeButton {
        width: 30%;
        font-size: 1.2rem !important;
    }

    .clearButton {
        width: 20%;
        font-size: 2rem !important;
    }

    .deleteIcon {
        margin-right: 1vw;
        font-size: 2rem !important;
    }

    .subTotal {
        font-size: 2.5rem !important;
    }
    
    .subTotalPrice {
        font-size: 2.5rem !important;
    }
    
    .deliveryIcon {
        font-size: 2.5rem !important;
    }

    .deliveryFee {
        font-size: 2.5rem !important;
    }
    
    .total { 
        font-size: 2.5rem !important;
    }
    
    .totalPrice {
        font-size: 2.5rem !important;
    }
    
    .checkout {
        width: 15% !important;
        height: 40%;
        font-size: 2rem !important;
        background-color: #1a1a1a !important;
        text-transform: capitalize;
        color: white;
        border-radius: 0.5vw !important;
        margin: 2vh auto !important;
    }
}