.productsPageContainer {
    display: grid;
    justify-content: center;
}

.productsContainer {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    background-color: #fcfbf9 !important;
    box-shadow: none;
}

.image {
    margin: 0.5vw;
    padding: 1vw !important;
    object-fit: cover; 
    aspect-ratio: 16/9; 
}

.image img {
    transform: rotate(0deg);
}

.productName {
    font-size: 1.4rem !important;
    font-weight: 500 !important;
    color: #1d1d1d !important;
    font-family: "Corbel Bold" !important;
    text-decoration: none !important;
}

.custom-link {
    text-decoration: none !important;
}

.price {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    color: #22211f !important;
    font-family: "Corbel" !important;
}

.addToCart {
    background-color: transparent !important;
    color: #a1824b !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    border: 1px solid #d49f60 !important;
    border-radius: 1vw !important;
    text-align: center !important;
    text-transform: uppercase;
    margin: 1vh auto !important;
    padding: 0.5vh 1.2vw !important;
    box-shadow: none !important;
}

.addToCart:hover {
    background-color: #d18726 !important;
    color: #1a1b19 !important;
    font-weight: 500 !important;
}

@media (max-width: 600px) {
    .css-ggq0th {
        margin: 2vh 0px !important;
    }

    .productsPageContainer {
        max-width: 400px !important;
        margin: auto !important;
    }

    .productsContainer {
        display: grid !important;
        grid-template-columns: 1fr !important;
        margin: 2vh auto !important;
        max-width: 400px !important;
    }

    .custom-link  {
        display: block !important;
        width: 30% !important;
        margin: auto !important;
        text-align: center !important;
    }
}

@media (max-width: 900px) {
    .productsContainer {
        display: grid !important;
        grid-template-columns: 1fr !important;
        margin: 2vh auto !important;
    }

    .custom-link  {
        display: block !important;
        width: 60% !important;
        margin: auto !important;
        text-align: center !important;
    }
}

@media (min-width: 2000px) {
    .productName {
        font-size: 2.5rem !important;
    }

    .price {
        font-size: 2.5rem !important;
    }

    .productCard {
        width: 800px !important;
        max-width: 500px !important;
    }

    .addToCart {
        font-size: 2rem !important;
        font-weight: 500 !important;
        border: 1px solid #d49f60 !important;
        border-radius: 1vw !important;
        padding: 1vh 3vw !important;
    }
}
