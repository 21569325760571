.footerContainer {
    background-color: #1a1a1a;
    margin: 0 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
    display: block !important;
    font-family: "Centaur Regular" !important;
}

.sendButton {
    background-color: #d9d9d9 !important;
    color: #1a1a1a !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important; 
    margin-top: 2vh !important;
}

.footerTitles {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    font-family: "Cormorant SC", serif !important;
}

.footerLinks {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerLinks a:hover {
    color: #d18726;
    cursor: pointer;
}
.footerLinks h3 {
    margin: 2vh 1vw;
}

.sendButton {
    background-color: #d18726 !important;
    color: white !important;
}

.sendButton:hover {
    background-color: #806044 !important;

}

.footer-1 h3:hover {
    color: #d18726;
    cursor: pointer;
}

@media (max-width: 600px) {
    .footerLinks {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
    }
}

