.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #d6d1cb;
    margin: 4vh auto;
    width: 80%;
    padding: 2vh 6vw;
    border-radius: 0.5vw;
}
  
.auth-wrapper .amplify-authenticator {
    width: 400px;
    padding: 20px;
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    border-radius: 10px;
}

/* Sign in button styles*/
.auth-wrapper .amplify-button--primary {
    background-color: transparent;
    border: 1px solid #1a1a1a; 
    color: #1a1a1a;
    width: 50%;
    margin: 2vh auto 0 auto;
    text-transform: uppercase;
    padding: 10px 10px;
    font-size: 16px; /* Custom font size */
    border-radius: 0.5vw !important;
    transition: background-color 0.3s ease;
}

.auth-wrapper .amplify-button--primary:hover {
    background-color: #d18726;
    border: 1px solid #d18726;

}

form {
    background-color: #d6d1cb;
}
  
input {
    background-color: #fcfbf9 !important;
    border-color: #fcfbf9 !important;
}

input:focus {
    border: 1px solid #d18726 !important;
    border-color: #d18726 !important;
}

/* Show password icon*/
.amplify-field__show-password {
    background-color: #fcfbf9 !important;
    border-color: #fcfbf9 !important;
}

.amplify-field__show-password:hover {
    color: #d18726 !important;
}

/*Tab buttons*/
button[role=tab] {
    background-color: #d6d1cb !important;
    color: #1a1a1a;
}

button[role=tab]:focus {
    color: #d18726 !important;
}

.amplify-tabs-item[data-state=active] {
    color: #d18726 !important
}
  
/* Form footer*/
[data-amplify-authenticator], [data-amplify-footer]  {
    background-color: #d6d1cb;
}


/* Forgot passsword button */
.amplify-button--link {
    color: #1a1a1a !important;
    text-decoration: underline !important;
}

.amplify-button--link:hover {
    background-color: transparent !important;
    color: #d18726 !important;
}

@media (min-width: 2000px) {
    input {
        font-size: 1.7rem !important;
    }

    .amplify-label {
        margin: 2vh auto;
        font-size: 2rem !important;
    }

    .auth-wrapper .amplify-button--primary {
        width: 55%;
        padding: 0.5vh;
        font-size: 1.5rem;
    }

    .amplify-button--link {
        font-size: 1.4rem !important;
    }

}