.parentContainer {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.paymentInfoContainer {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    margin: 4vh 1vw !important;
    width: 80% !important;
    background-color: #d6d1cb !important;
    border-radius: 0.5vw !important;
}

.paymentMethod {
    display: flex;
    flex-direction: column !important;
    align-content: center;
    margin: 2vh auto !important;
}

.paymentMethod h2 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
}

.paymentText {
    margin-top: 2vh;
    font-size: 1.5rem;
}

.paymentButton .MuiToggleButton-root {
    background-color: transparent; 
    border: 1px solid #1a1a1a;
    color: black;
    height: 6vh;
    margin-top: 2vh;
    padding: 1vw;
  }
  
.paymentButton .MuiToggleButton-root.Mui-selected {
    background-color: #1a1a1a;
    color: white; 
}
  
.paymentButton .MuiToggleButton-root.Mui-selected:hover {
    background-color: #d18726; 
    color: white;
}
  
.paymentButton .MuiToggleButton-root:focus {
    outline: none;
}
  
.paymentButton .MuiToggleButton-root:hover {
    background-color: #d18726; 
    color: white;
}

.cardDetails {
    width: 40% !important;
    margin: 4vh auto !important;
}

.orderButton {
    background-color: #908585 !important;
    color: white;
    border-radius: 0.5vw !important;
    margin: 2vh auto !important;
    border: 1px solid #4f4c4b !important;
    text-transform: capitalize !important;
    width: 15% !important;
}

.orderButton:hover {
    background-color: #d18726 !important;
    font-weight: 600 !important;
    border: 1px solid #d18726 !important;
}

.MuiInputLabel-root.Mui-focused {
    color: #d18726 !important;
    font-weight: 600 !important;
}


@media (max-width: 600px) {
    .orderButton {
        width: 50% !important;
    }
}

@media (min-width: 610px) and (max-width: 900px) {
    .orderButton {
        width: 35% !important;
    }
}

@media (min-width: 2000px) {
    .paymentInfoContainer {
        margin: 2vh 1vw 4vh 1vw !important;
    }

    .paymentMethod h2 {
        font-size: 3rem !important;
    }

    .paymentButton .MuiToggleButton-root {
        height: 7vh !important;
        width: 300px !important;
        font-size: 1.8rem !important;
        padding: 1vh 2vw !important;
    }

    #contact-form {
        font-size: 3rem !important;
        font-weight: 400 !important;
        margin-top: 2vh !important;
        margin-bottom: 1vh !important;
    }

    .orderButton {
        width: 15% !important;
    }
}