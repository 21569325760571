.navbar-container {
    background-color: #c0a177 !important;
    box-shadow: none !important;
    position: fixed;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    color: #1a1a1a !important;
    margin: 0 !important;
}

.links {
    color: #1a1a1a !important;
    font-size: 1.1rem !important;
    text-transform: capitalize !important;
    font-family: 'Poppins' !important;
}

.companyLogo {
    width: 15vw;
} 

ul {
    background-color: #c0a177 !important;
}

@media (max-width: 600px) {
    .companyLogo {
        width: 40vw;
    }
}

@media (min-width: 610px) and (max-width: 900px) {
    .companyLogo {
        width: 30vw;
    }
}

@media (min-width: 2000px) {
    .links {
        font-size: 1.6rem !important;
    }

    .shoppingCartIcon {
        font-size: 2rem !important;
    }
}